
import { computed, defineComponent } from 'vue';

// 步骤信息接口
interface StepInterface {
  title?: string;
  icon?: string;
}
export default defineComponent({
  name: 'PackagedSteps',
  emits: ['change'],
  props: {
    width: {
      type: String,
      default: '100%',
    },
    active: {
      type: Number,
      default: 1,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  setup(props: { width: string; active: number | string; data: Array<any> }) {
    const computedStepWidth = computed(() => `${Math.floor(100 / props.data.length)}%`);
    return {
      computedStepWidth,
    };
  },
});
