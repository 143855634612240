
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import PackagedSteps from '@/components/packaged-steps/Index.vue';
import Email from './components/Email.vue';
import Password from './components/Password.vue';
// eslint-disable-next-line @typescript-eslint/no-require-imports
const tencentLogo = require('../../assets/img/tencent-logo.png');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const citybaseLogo = require('../../assets/img/citybase-logo.png');
// eslint-disable-next-line @typescript-eslint/no-require-imports
const completeLogo = require('../../assets/img/complete.png');
enum Types {
  email = 'email',
  password = 'password',
  Complete = 'Complete',
}

export default defineComponent({
  name: 'ForgetPassword',
  components: {
    PackagedSteps,
    Email,
    Password,
    Complete: {
      setup(props, ctx) {
        return () => ctx.slots.default && ctx.slots.default();
      },
    },
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      activeStep: 1,
      steps: [{ title: '1.验证邮箱' }, { title: '2.重置密码' }, { title: '3.完成' }],
      componentName: 'Email',
    });
    const captcha = ref('');
    const backEmail = ref('');
    // 切换组件
    const onSubmit = (payload: { type: Types; captcha: string; email: string }) => {
      switch (payload.type) {
        case 'email':
          state.activeStep = 1;
          break;
        case 'password':
          state.activeStep = 2;
          break;
        case 'Complete':
          state.activeStep = 3;
          break;
      }
      state.componentName = payload.type;
      captcha.value = payload.captcha ? payload.captcha : '';
      backEmail.value = payload.email ? payload.email : '';
    };

    const backToLogin = () => {
      router.push('/login');
    };
    return {
      ...toRefs(state),
      tencentLogo,
      citybaseLogo,
      completeLogo,
      captcha,
      onSubmit,
      backEmail,
      backToLogin,
    };
  },
});
