
import { ElMessage } from 'element-plus';
import { validateVerifyCode, sendRetrievePasswordVerifyCode } from '@/api/tenant';
import { checkMail } from '@/utils/validate';
import {
  defineComponent,
  PropType,
  WritableComputedRef,
  computed,
  reactive,
  ref,
  SetupContext,
  onBeforeUnmount,
} from 'vue';

interface EmailInfoInterface {
  email: string;
  captcha: string;
}

export default defineComponent({
  name: 'Email',
  props: {
    data: {
      type: Object as PropType<EmailInfoInterface>,
      default: () => ({ email: '', captcha: '' }),
    },
  },
  emits: ['submit'],
  setup(props: { data: EmailInfoInterface }, ctx: SetupContext) {
    const emailInfos: WritableComputedRef<EmailInfoInterface> = computed(() => props.data);
    const emailInfo: EmailInfoInterface = reactive({
      email: '',
      captcha: '',
    });
    const form: any = ref(null);
    const isToash = ref(false);
    const timeout = ref(null as any);
    const reSend = ref('');
    const validatorMailPass = (rule: any, value: string, callback: Function) => {
      if (!checkMail(value)) {
        callback(new Error('请输入正确的邮箱格式'));
      } else {
        callback();
      }
    };
    const checkCaptcha = (rule: any, captcha: string): boolean => {
      const reg = /^\d{6}$/;
      return reg.test(captcha);
    };

    const rules = {
      email: [
        { required: true, message: '请输入验证邮箱号', trigger: 'blur' },
        {
          validator: validatorMailPass,
          message: '请输入正确的邮箱格式',
          trigger: 'blur',
        },
      ],
      captcha: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        {
          validator: checkCaptcha,
          message: '验证码应为6位有效数字',
          trigger: 'blur',
        },
      ],
    };

    const sendVerifyCode = async () => {
      const isLawful = checkMail(emailInfo.email);
      if (!emailInfo.email) {
        ElMessage({
          type: 'error',
          message: '请输入邮箱号',
        });
      } else {
        if (!isLawful) {
          ElMessage({
            type: 'error',
            message: '邮箱格式错误',
          });
        } else {
          ElMessage({
            type: 'success',
            message: '验证码发送成功',
          });
          const { code } = await sendRetrievePasswordVerifyCode({
            userEmail: emailInfo.email,
          });
          if (code === 0) {
            isToash.value = true;
            let timeo = 60;
            reSend.value = `重新发送(${timeo})s`;
            timeout.value = setInterval(() => {
              // eslint-disable-next-line no-plusplus
              timeo--;
              if (timeo > 0) {
                reSend.value = `重新发送(${timeo})s`;
              } else {
                timeo = 60;
                isToash.value = false;
                clearInterval(timeout.value);
              }
            }, 1000);
          }
        }
      }
    };

    const submit = () => {
      // eslint-disable-next-line no-unused-expressions
      form.value?.validate(async (valid: boolean) => {
        if (valid) {
          const { data } = await validateVerifyCode({
            userEmail: emailInfo.email,
            verifyCode: emailInfo.captcha,
          });
          if (data === true) {
            ElMessage({
              type: 'success',
              message: '验证码输入成功',
            });
            const payload = {
              email: emailInfo.email,
              captcha: emailInfo.captcha,
              type: 'password',
            };
            ctx.emit('submit', payload);
          } else {
            ElMessage({
              type: 'error',
              message: '验证码错误',
            });
          }
        }
      });
    };

    onBeforeUnmount(() => {
      // window.onbeforeunload = function () {
      //   return '系统可能不会保存您所做的更改。';
      // };
      clearInterval(timeout.value);
    });

    return {
      emailInfo,
      form,
      rules,
      isToash,
      reSend,
      emailInfos,
      sendVerifyCode,
      submit,
    };
  },
});
